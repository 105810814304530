import { computed } from '@vue/composition-api'
export default function (slots) {
  const classes = computed(() => {
    // Use default slot count to append additional classes
    if (slots.default && slots.default().length > 1) {
      return ['justify-between']
    }
    return ['justify-end']
  })

  return {
    classes,
  }
}
