import { render, staticRenderFns } from "./AppModalFooter.vue?vue&type=template&id=62df1965&scoped=true&"
import script from "./AppModalFooter.vue?vue&type=script&lang=js&"
export * from "./AppModalFooter.vue?vue&type=script&lang=js&"
import style0 from "./AppModalFooter.vue?vue&type=style&index=0&id=62df1965&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62df1965",
  null
  
)

export default component.exports