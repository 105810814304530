<template>
  <AppCardFooter class="card-footer--modal-buttons">
    <slot></slot>
  </AppCardFooter>
</template>

<script>
import AppCardFooter from '@/components/AppCardFooter/AppCardFooter'
export default {
  name: 'AppModalFooter',
  components: {
    AppCardFooter,
  },
  emits: [],
  props: {},
  setup() {
    return {}
  },
}
</script>

<style lang="postcss" scoped>
.card-footer--modal-buttons {
  @apply fixed bottom-0 left-0;
  @apply w-full;
  @apply border-t border-gray-lightest;
  @apply shadow-ria-1;
  @apply flex;

  @screen sm {
    /* Resets */
    @apply static;
    @apply shadow-none;
    @apply border-none;
  }
}
</style>
