<template>
  <footer class="card-footer" :class="classes">
    <slot></slot>
  </footer>
</template>

<script>
import useAppCardFooter from '@/composables/useAppCardFooter'
import { onUnmounted, reactive, watch } from '@vue/composition-api'
import useAlert from '@/composables/useAlert/index'
import { useMediaQuery } from '@/composables/useMediaQuery'

export default {
  name: 'AppCardFooter',
  emits: [],
  props: {},
  setup(props, { slots }) {
    const { classes } = useAppCardFooter(slots)

    const { isAlertBtmMargin } = useAlert()
    const mq = reactive(useMediaQuery())

    //Watch for mq and add extra bottom margin for the SnackAlert
    watch(mq, (mq) => {
      mq.current === 'xs'
        ? (isAlertBtmMargin.value = true)
        : (isAlertBtmMargin.value = false)
    })

    onUnmounted(() => {
      isAlertBtmMargin.value = false
    })

    return {
      classes,
    }
  },
}
</script>

<style lang="postcss" scoped>
.card-footer {
  @apply fixed bottom-0 left-0 right-0;
  @apply bg-white;
  @apply flex;
  @apply shadow-inset-1;
  @screen sm {
    @apply pt-14;
    @apply static bottom-auto left-auto right-auto;
    @apply bg-transparent;
    @apply shadow-none;
  }
  .button {
    @apply rounded-none;
    @apply w-full;
    @apply border-none;
    @screen sm {
      @apply w-auto rounded-xxl;
      @apply border-solid;
    }
  }
}

.input + .card-footer {
  @screen sm {
    @apply pt-8;
  }
}
</style>
