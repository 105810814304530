<template>
  <AppModal
    v-if="true"
    :value="true"
    :dismissible="true"
    :content-centered="true"
    :persistent="true"
    class="processing-information-modal"
  >
    <!-- Main content -->
    <div class="text-center">
      <template>
        <div class="app-icon pb-5">
          <span role="img" aria-labelledby="Error">&#9203;</span>
        </div>
      </template>
      <h1 class="mb-4 type-h1">
        We are processing <br />
        your information
      </h1>
      <p v-if="isApiUser" class="text-text-gray-primary type-body">
        We'll email you to let you know when your account's approved.
        <span class="pb-3 whitespace-pre block" />
        Once approved, use the login details you provided at registration to access Xe from your
        business central platform.
      </p>
      <p v-else class="text-text-gray-primary type-body">
        You can expect an email from us when your account is approved.
        <span class="pb-3 whitespace-pre block" />
        You will be redirected to a limited account view while we are reviewing your information.
      </p>

      <!-- Footer Desktop -->
      <div class="pt-6">
        <!-- Go to FX web -->
        <AppButton
          v-if="mq.current !== 'xs'"
          type="submit"
          class="w-full my-2"
          @click="goToTransferXe"
        >
          <span v-if="isApiUser">Continue</span>
          <span v-else>Take me there</span>
        </AppButton>
      </div>
    </div>

    <!-- Footer Mobile-->
    <template v-if="mq.current == 'xs'" #footer>
      <!-- Go to FX web -->
      <AppModalFooter>
        <AppButton class="documentary-evidence-button" @click="goToTransferXe">
          <span v-if="isApiUser">Continue</span>
          <span v-else>Take me there</span>
        </AppButton>
      </AppModalFooter>
    </template>
  </AppModal>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { useRouter } from '@/composables/useRouter'
import { useStore } from '@/composables/useStore'
import { useMediaQuery } from '@/composables/useMediaQuery'

import AppButton from '@/components/AppButton/AppButton'
import AppModal from '@/components/AppModal/AppModal'
import AppModalFooter from '@/components/AppModalFooter/AppModalFooter'
import { useDashboardStore } from '../../stores/dashboard'
import { useAuthStore } from '../../stores/auth'

export const errorTypeData = Object.freeze({
  general: {
    titleText: 'Something went wrong!',
    paragraphText: 'Sorry, there’s an unexpected error. Please try again later.',
    buttonText: 'Try again',
    stepId: -1,
    showBackArrow: false,
  },
  duplicate: {
    titleText: 'You’re already registered',
    paragraphText: 'Looks like your company already has an account. Try to login instead.',
    buttonText: 'Login',
    stepId: 0,
    showBackArrow: true,
  },
  natureOfBusiness: {
    titleText: 'We can’t finish your registration',
    paragraphText: 'Sorry, we’re unable to serve the industry you’ve selected',
    buttonText: 'Okay',
    stepId: 0,
    showBackArrow: true,
  },
})

export default {
  name: 'RegisterError',
  components: {
    AppButton,
    AppModal,
    AppModalFooter,
  },
  props: {
    errorType: {
      type: String,
      default: 'general',
    },
  },
  setup(props) {
    const router = useRouter()
    const store = useStore()
    const dashboardStore = useDashboardStore()
    const authStore = useAuthStore()

    const errorType = errorTypeData[props.errorType]
    const showBackArrow = errorType.showBackArrow
    const titleText = errorType.titleText
    const paragraphText = errorType.paragraphText
    const buttonText = errorType.buttonText
    const isApiUser = dashboardStore.corpProfile?.isApiUser ?? false

    const onClick = () => {
      if (props.errorType === 'duplicate') {
        window.location.href = authStore.loginUrl
      } else if (props.errorType === 'natureOfBusiness') {
        window.location = 'https://www.xe.com/mt/ca-business/' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      } else {
        // remove "leave site" listener
        window.removeEventListener('beforeunload', window.handleWindowClose)
        router.push({ name: 'PendingActions' }) // TODO: add correct redirect route
      }
    }

    const onContactUs = () => {
      window.location = 'https://www.xe.com/contact/faq/' // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    }

    const goToTransferXe = () => {
      // remove persisted questionsAndAnswers, documents and auth personnel from store
      dashboardStore.setClearSessionStore()
      window.removeEventListener('beforeunload', window.handleWindowClose)

      const redirectUrl = dashboardStore.getRedirectUrl()
      localStorage.clear()
      window.location = redirectUrl
    }

    return {
      showBackArrow,
      buttonText,
      onClick,
      onContactUs,
      goToTransferXe,
      paragraphText,
      titleText,
      mq: reactive(useMediaQuery()),
      isApiUser,
    }
  },
}
</script>

<style lang="postcss" scoped>
.processing-information {
  &-modal {
    /deep/ .overlay-content {
      height: 100%;
    }

    /deep/ .card {
      @screen sm {
        @apply mt-12;
      }
    }
  }
}
.app-icon {
  @apply mt-6;

  span {
    @apply text-4xl;
  }
}
</style>
