<template>
  <AppOverlay
    v-model="visible"
    :dismissible="dismissible"
    class="app-modal"
    @showing="onShowing"
    @show="onShow"
    @shown="onShown"
    @hiding="onHiding"
    @hide="onHide"
    @hidden="onHidden"
  >
    <AppCard :class="appCardClasses">
      <template v-if="$slots.header" #header>
        <slot name="header" />
      </template>

      <slot></slot>

      <slot name="footer" />
    </AppCard>
  </AppOverlay>
</template>

<script>
import { computed } from '@vue/composition-api'

import AppOverlay from '@/components/AppOverlay/AppOverlay'
import AppCard from '@/components/AppCard/AppCard'

import useAppOverlay from '@/composables/useAppOverlay'

export default {
  name: 'AppModal',
  components: {
    AppOverlay,
    AppCard,
  },
  emits: ['input', 'showing', 'show', 'shown', 'hiding', 'hide', 'hidden'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    contentCentered: {
      type: Boolean,
      default: false,
    },
    contentNoPadding: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, slots }) {
    const appCardClasses = computed(() => {
      const classes = []
      if (slots.header) {
        classes.push('card--has-header')
      }
      if (slots.footer) {
        classes.push('card--has-footer')
      }
      if (props.contentCentered) {
        classes.push('card--content-centered')
      }
      if (props.contentNoPadding) {
        classes.push('card--content-no-padding')
      }

      return classes
    })

    const {
      visible,
      onShowing,
      onShow,
      onShown,
      onHiding,
      onHide,
      onHidden,
    } = useAppOverlay(props, emit)

    return {
      visible,
      appCardClasses,
      onShowing,
      onShow,
      onShown,
      onHiding,
      onHide,
      onHidden,
    }
  },
}
</script>

<style lang="postcss" scoped>
.app-modal {
  /deep/ .app-overlay-content,
  .app-overlay-content {
    @apply m-0;
    @apply h-full;
    @apply max-w-full;
    @apply py-0;

    .card {
      @apply bg-white;
      @apply flex flex-col;

      @screen sm {
        @apply h-auto;
        @apply my-16;
      }

      .card-header {
        @apply flex;
        @apply p-0;
        @apply mb-0;

        &:not(.card-header--without-title) {
          @apply border-b border-gray-light border-solid;
        }

        @screen sm {
          /* Resets */
          @apply pt-7 px-7;
          @apply mb-5;

          &:not(.card-header--without-title) {
            @apply border-none;
          }
        }
      }

      .card-content {
        @apply flex-grow;
        @apply py-6;
        @apply overflow-y-auto;

        @screen sm {
          @apply py-12;
        }
      }

      /* Utils styling */
      &.card--has-footer {
        .card-content {
          @apply pb-12;
        }
      }

      &.card--has-header {
        .card-content {
          @apply pt-0;

          @screen sm {
            @apply pt-0;
          }
        }
      }

      &.card--content-centered {
        .card-content {
          @apply flex flex-col items-center align-middle;
        }
      }

      &.card--content-no-padding {
        .card-content {
          @apply pt-0 !important;
        }
      }
    }

    @screen sm {
      /* Resets */
      @apply max-w-md;
      @apply m-auto;
      @apply h-auto;
    }
  }
}
</style>
